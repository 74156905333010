import React from "react";

const Review3 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
    <path
      fill="#FCEA2B"
      d="M35.97 6.037L27.769 22.67 9.417 25.344l13.285 12.94-3.128 18.28 16.412-8.636 16.419 8.624-3.142-18.278 13.275-12.95-18.354-2.66zM14.164 41.459l-9.305.578 1.617 4.696zm43.673-.48l9.304.578-1.617 4.696zM22.072 15.592l-3.424-8.671-3.967 2.989zm28.328.494l7.585-5.42-3.86-3.126zM35.92 56.978l-2.484 8.985h4.967z"
    ></path>
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M35.925 6.037L27.723 22.67 9.371 25.344l13.285 12.94-3.128 18.28 16.412-8.636 16.419 8.624-3.142-18.278 13.276-12.95-18.354-2.66zm.007 50.941l-2.484 8.985h4.968zm21.884-15.899l7.778 5.138 1.535-4.724zm-43.64.38l-9.305.578 1.618 4.696zm36.237-25.373l7.584-5.42-3.86-3.126zm-28.329-.494L18.66 6.921 14.693 9.91z"
    ></path>
  </svg>
);

export default Review3;