import React from "react";

const Wellbeing1 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
      <path
        fill="#FCEA2B"
        d="M36 13.2c-12.572 0-22.8 10.228-22.8 22.8 0 12.572 10.228 22.8 22.8 22.8 12.572 0 22.8-10.228 22.8-22.8 0-12.572-10.228-22.8-22.8-22.8z"
      ></path>
      <g stroke="#000" strokeMiterlimit="10" strokeWidth="2">
        <circle cx="36" cy="36" r="23" fill="none"></circle>
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.88 23.92c5.102-.061 7.273-1.882 8.383-3.346"
        ></path>
        <path d="M46.24 47.56c0-2.592-2.867-7.121-10.25-6.93-6.974.181-10.22 4.518-10.22 7.111s4.271-1.611 10.05-1.492c6.317.13 10.43 3.903 10.43 1.311z"></path>
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M23.16 28.47c5.215 1.438 5.603.91 8.204 1.207 1.068.122-2.03 2.67-7.282 4.397M50.12 23.92c-5.102-.061-7.273-1.882-8.383-3.346m7.103 7.896c-5.215 1.438-5.603.91-8.204 1.207-1.068.122 2.03 2.67 7.282 4.397"
        ></path>
      </g>
    </svg>
);

export default Wellbeing1;