import Review1 from './Review1';
import Review2 from './Review2';
import Review3 from './Review3';
import Wellbeing1 from './Wellbeing1';
import Wellbeing2 from './Wellbeing2';
import Wellbeing3 from './Wellbeing3';
import Wellbeing4 from './Wellbeing4';
import Wellbeing5 from './Wellbeing5';

const icons = {
    Review1,
    Review2,
    Review3,
    Wellbeing1,
    Wellbeing2,
    Wellbeing3,
    Wellbeing4,
    Wellbeing5
};

export default icons;
