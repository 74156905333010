import React from "react";

const Review1 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
      <path d="M35.993 10.736L27.79 27.37 9.44 30.044l13.285 12.94-3.128 18.28 16.412-8.636 16.419 8.623-3.142-18.277 13.276-12.95-18.354-2.66-8.214-16.628z"></path>
      <path
        fill="#3F3F3F"
        d="M35.993 10.736L27.79 27.37 9.44 30.044l13.285 12.94-3.128 18.28 16.412-8.636 16.419 8.623-3.142-18.277 13.276-12.95-18.354-2.66-8.214-16.628z"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M35.993 10.736L27.79 27.37 9.44 30.044l13.285 12.94-3.128 18.28 16.412-8.636 16.419 8.623-3.142-18.277 13.276-12.95-18.354-2.66-8.214-16.628z"
      ></path>
    </svg>
);

export default Review1;